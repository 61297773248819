import {
  colorBlueDark,
  colorWhite,
  mediaTabletLandscape,
  sizeLarge,
  sizeXlarge,
  sizeXsmall,
  sizeXxxlarge,
} from "@10xdev/design-tokens";
import { css, Global } from "@emotion/react";
import type { FunctionComponent } from "react";
import { createRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import FocusLock from "react-focus-lock";
import { RemoveScroll } from "react-remove-scroll";

import { useKey } from "react-use";

import LogoDark from "../../assets/branding/logo-dark.svg";
import Icon from "../Icon";
import IconButton from "../IconButton";
import MarketoForm from "../MarketoForm";
import type { MarketoFormResponse } from "../MarketoForm/types";
import Paragraph from "../Paragraph";
import Text from "../Text";
import type { FormStep } from "./types";

interface Props {
  image?: string | null;
  onClose: () => void;
  onSuccess?: () => void;
  passedValues?: any;
  steps: Array<FormStep>;
  submitButtonID?: string;
}

const ModalForm: FunctionComponent<Props> = ({
  image,
  onClose,
  onSuccess,
  passedValues,
  steps,
  submitButtonID = "submitButton",
}) => {
  const [stepNumber, setStepNumber] = useState(0);
  const [form, setForm] = useState<MarketoFormResponse | null>(null);
  const [target, setTarget] = useState<HTMLDivElement | null>(null);

  const step = steps[stepNumber];

  const handleFormSubmitSuccess = () => {
    setForm(null);

    const nextStep = stepNumber + 1;
    if (nextStep < steps.length) {
      setStepNumber(nextStep);
    }

    if (onSuccess) {
      onSuccess();
    }
  };

  // Create target elements for modal
  useEffect(() => {
    const newTarget = document.createElement("div");
    newTarget.setAttribute("class", "ModalForm");
    newTarget.setAttribute("role", "dialog");
    newTarget.setAttribute(
      "style",
      [
        "bottom: 0",
        "left: 0",
        "position: fixed",
        "right: 0",
        "top: 0",
        "z-index: 1000",
      ].join("; "),
    );
    newTarget.setAttribute("tabIndex", "-1");
    document.body.appendChild(newTarget);
    setTarget(newTarget);

    return function cleanup() {
      document.body.removeChild(newTarget);
      setTarget(null);
    };
  }, []);

  // Load form config from Marketo
  useEffect(() => {
    const loadFormConfig = async () => {
      if (step.marketoFormID) {
        try {
          const response = await fetch(`/api/form/${step.marketoFormID}`);
          const json = await response.json();
          setForm(json);
        } catch (error) {
          console.warn(error);
          setForm(null);
        }
      }
    };
    loadFormConfig();
  }, [stepNumber, step.marketoFormID]);

  const closeButtonRef = createRef<HTMLButtonElement>();
  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, [closeButtonRef]);

  useKey("Escape", onClose);

  return target
    ? ReactDOM.createPortal(
        <FocusLock returnFocus={true}>
          <RemoveScroll>
            <Global
              styles={css`
                body {
                  overflow: hidden;
                }
              `}
            />

            <div
              css={css`
                background-color: ${colorWhite};
                bottom: 0;
                display: flex;
                flex-direction: row;
                left: 0;
                outline: none;
                position: absolute;
                right: 0;
                top: 0;
              `}
              role={"dialog"}
              tabIndex={0}
            >
              <figure
                css={css`
                  align-items: center;
                  background-color: ${colorBlueDark};
                  display: flex;
                  justify-content: center;
                  margin: 0;
                  padding: 0;
                  width: 100%;

                  @media (max-width: ${mediaTabletLandscape}) {
                    display: none;
                  }
                `}
              >
                {image && (
                  <img
                    alt={passedValues["Last_Touch_Offer_Name__c"]}
                    css={css`
                      width: 70%;
                    `}
                    src={image}
                  />
                )}
              </figure>

              <div
                css={css`
                  box-sizing: border-box;
                  display: flex;
                  justify-content: center;
                  margin: 0;
                  min-width: 600px;
                  overflow-y: scroll;
                  padding: ${sizeXxxlarge} ${sizeXxxlarge};

                  @media (max-width: ${mediaTabletLandscape}) {
                    min-width: unset;
                    padding: ${sizeXlarge} ${sizeXsmall};
                    width: 100%;
                  }
                `}
              >
                <div
                  css={css`
                    width: 360px;
                  `}
                >
                  <LogoDark
                    css={css`
                      margin-bottom: 3rem;
                      max-height: 3rem;
                    `}
                    width={"12rem"}
                  />

                  <Text
                    as={"h1"}
                    css={css`
                      margin-bottom: 1rem;
                    `}
                    size={"xxlarge"}
                    weight={"semibold"}
                  >
                    {step.title}
                  </Text>

                  <Paragraph size={"medium"}>{step.description}</Paragraph>

                  {form ? (
                    <MarketoForm
                      form={form}
                      onSuccess={handleFormSubmitSuccess}
                      passedValues={passedValues}
                      submitID={submitButtonID}
                    />
                  ) : null}
                </div>
              </div>

              <IconButton
                css={css`
                  position: absolute;
                  right: ${sizeLarge};
                  top: ${sizeLarge};
                `}
                onClick={onClose}
                ref={closeButtonRef}
              >
                <Icon color={"midgray"} size={"18px"} source={"close"} />
              </IconButton>
            </div>
          </RemoveScroll>
        </FocusLock>,
        target,
      )
    : null;
};

export default ModalForm;
